import { featureListCoversFeature, minimizeSetOfFeatures } from "./Features";
export var RankingOrder;
(function (RankingOrder) {
    RankingOrder["Superior"] = "Superior";
    RankingOrder["Inferior"] = "Inferior";
    RankingOrder["Equal"] = "Equal";
    RankingOrder["Sibling"] = "Sibling";
})(RankingOrder || (RankingOrder = {}));
export var RoleIdentifiers;
(function (RoleIdentifiers) {
    // TODO:  these are copied from backend and are not 100% trust-worthy
    //        but since the Role concept does not include a way to cluster
    //        roles by the semantics of "is admin-like", this will have to
    //        for now.
    RoleIdentifiers["Owner"] = "owner";
    RoleIdentifiers["SuperAdmin"] = "superadmin";
    RoleIdentifiers["Admin"] = "admin";
    RoleIdentifiers["Programmer"] = "programmer";
    RoleIdentifiers["User"] = "user";
})(RoleIdentifiers || (RoleIdentifiers = {}));
export class Role {
    constructor(params) {
        this.identifier = params.identifier;
        this.name = params.name;
        this.ranking = params.ranking;
        this.featureArray = [...params.features];
    }
    get features() {
        return [...this.featureArray];
    }
    getRankingOrder(another) {
        return Role.getRankingOrder(this.ranking, another.ranking);
    }
    hasFeature(feature) {
        return featureListCoversFeature(this.featureArray, feature);
    }
    equals(other) {
        return Role.equal(this, other);
    }
    static fromGraphQL(role) {
        return new Role(Object.assign(Object.assign({}, role), { identifier: role.id }));
    }
    /**
     * Returns the relative ordering of two {@link Ranking} values.
     * This method can be used with sort functions: [].sort(rankingOrder).
     *
     * NOTE:
     *    if the two rankings are siblings (AB and AC)
     *    or from entirely different rank trees (AB, BC)
     *    the function will return a 0 for those rankings.
     *
     * @param ranking1
     * @param ranking2
     * @returns number representing the relative order of the ranks
     * @see getRankingOrder for a more nuanced method
     */
    static rankingOrder(ranking1, ranking2) {
        if (ranking1 === ranking2)
            return 0;
        if (ranking2.startsWith(ranking1))
            return Number.NEGATIVE_INFINITY;
        if (ranking1.startsWith(ranking2))
            return Number.POSITIVE_INFINITY;
        // the rankings are either siblings or from entirely different rank-trees
        // in that case they will be treated as equals
        return 0;
    }
    /**
     * Returns a {@link RankingOrder} between the two given rankings answering the question
     * "What is the relations of ranking1 to ranking2?"
     *
     * @param ranking1
     * @param ranking2
     */
    static getRankingOrder(ranking1, ranking2) {
        const order = Role.rankingOrder(ranking1, ranking2);
        if (ranking1 === ranking2)
            return RankingOrder.Equal;
        if (order < 0)
            return RankingOrder.Superior;
        if (order > 0)
            return RankingOrder.Inferior;
        return RankingOrder.Sibling;
    }
    /**
     * Collects and minimized features granted by the given roles.
     * @param roles
     */
    static getMinimizedFeaturesForRoles(roles) {
        const allFeatures = roles.flatMap((role) => role.features);
        const minimalSet = minimizeSetOfFeatures(new Set(allFeatures));
        return [...minimalSet];
    }
    /**
     * Checks that all {@link Feature features} are covered by any {@link Role} in the roles list.
     * @param roles
     * @param features
     */
    static rolesHaveFeatures(roles, features) {
        const roleFeatures = this.getMinimizedFeaturesForRoles(roles);
        return features.every((feature) => featureListCoversFeature(roleFeatures, feature));
    }
    static equal(role1, role2) {
        return role1.identifier === role2.identifier;
    }
}
