import { SxProps, Theme, Typography } from "@mui/material";
import { translations } from "../../generated/translationHelper";
import { getYear } from "date-fns";
import React, { FunctionComponent } from "react";

type Props = {
  sx?: SxProps<Theme>;
};

export const CopyrightAndTrademark: FunctionComponent<Props> = (props: Props) => {
  // &#32; is a regular space. It is used to allow for breaking, which happens in the login views.
  return (
    <Typography
      variant="caption"
      sx={{ whiteSpace: "nowrap", padding: "24px", display: "inline-block", ...props.sx }}
      color="text.disabled"
    >
      {translations.common.texts.copyright({ year: getYear(new Date()) })}
      <span style={{ whiteSpace: "wrap" }}>&#32;</span>
      {translations.common.texts.trademark()}
    </Typography>
  );
};
