import { Alert, Button, Grid, List, ListItem, Paper, Snackbar, Typography } from "@mui/material";
import React, { Component, Fragment } from "react";
import { ProfileFieldItem } from "./components/profile-field-item";
import { AuthWrapper, Maybe } from "@sade/data-access";
import { fetchLanguageFromBackend } from "../../locales/localizationUtils";
import Loader from "../ui/loader";
import { translations } from "../../generated/translationHelper";
import EditNameDialog from "./components/edit-name";
import EditLanguageDialog from "./components/edit-language";
import ConfirmationDialog from "../ui/confirmation-dialog";
import EditMobileDialog from "./components/edit-mobile";
import EditEmailDialog from "./components/edit-email";
import EditPasswordDialog from "./components/edit-password";
import { ProfileAvatar } from "./components/profile-avatar";
import { Logout } from "@mui/icons-material";
import { CopyrightAndTrademark } from "../ui/copyright-and-trademark";

type EditOption = "Name" | "Email" | "Mobile" | "Password" | "Language";
type UserDetails = {
  givenName: Maybe<string>;
  familyName: Maybe<string>;
  phoneNumber: Maybe<string>;
  language: string;
  email: Maybe<string>;
};

interface Props {}

interface State {
  userDetails?: UserDetails;
  dialogOpen: boolean;
  editOption?: EditOption;
  logOutDialogOpen: boolean;
  savedState: boolean;
  errorMessage?: string;
}

export default class UserView extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      dialogOpen: false,
      logOutDialogOpen: false,
      savedState: false,
    };
  }

  public componentDidMount(): void {
    this.updateUserDetails().catch((e) => {
      console.error(e);
      const errorDetail = "message" in e ? e.message : translations.common.texts.unknownError();
      this.setState({
        errorMessage: translations.common.texts.errorOccurred({ error: errorDetail }),
      });
    });
  }

  public componentDidUpdate(prevState: State): void {
    if (prevState.userDetails?.language !== this.state.userDetails?.language) {
      return;
    }
  }

  private updateUserDetails = async (): Promise<void> => {
    const [givenName, familyName, phoneNumber, language, email] = await Promise.all([
      AuthWrapper.getGivenName(),
      AuthWrapper.getFamilyName(),
      AuthWrapper.getPhoneNumber(),
      fetchLanguageFromBackend(),
      AuthWrapper.getEmail(),
    ]);
    this.setState({
      userDetails: {
        givenName,
        familyName,
        phoneNumber,
        language: language ?? "en",
        email,
      },
    });
  };

  private displayUserName = (): string => {
    const { givenName, familyName } = this.state.userDetails ?? {};
    const nameArray = [givenName, familyName];
    return nameArray.filter((str) => str).join(" ");
  };

  private handleFieldEdit = (editOption: EditOption): void => {
    if (this.state.savedState || !!this.state.errorMessage) {
      this.handleSnackbarClose();
    }
    this.setState(
      {
        dialogOpen: true,
        editOption,
      },
      () => console.log("Edit field:", this.state.editOption)
    );
  };

  private handleDialogClose = async (saveSuccessful?: boolean): Promise<void> => {
    this.setState({
      dialogOpen: false,
    });
    if (saveSuccessful === undefined) {
      // No need to show snackbar
      return;
    } else if (saveSuccessful) {
      // Show success snackbar
      await this.updateUserDetails();
      if (this.state.editOption === "Language") {
        window.location.reload();
      }
      this.setState({
        savedState: true,
      });
    } else {
      // Show error snackbar
      this.setState({
        errorMessage: translations.user.texts.failedToSaveChanges(),
      });
    }
  };

  private handleSnackbarClose = (event?: Event | React.SyntheticEvent<Element, Event>, reason?: string): void => {
    if (reason === "clickaway") return;
    this.setState({
      savedState: false,
      errorMessage: undefined,
    });
  };

  private generateSnackbarText = (): Maybe<string> => {
    if (!this.state.editOption) return;
    const { editOption } = this.state;
    if (editOption === "Name") return translations.user.texts.successInNameChange();
    if (editOption === "Email") return translations.user.texts.successInEmailChange();
    if (editOption === "Mobile") return translations.user.texts.successInPhoneNumberChange();
    if (editOption === "Password") return translations.user.texts.successInPasswordChange();
    if (editOption === "Language") return translations.user.texts.successInLanguageChange();
    if (editOption === "Password") return translations.user.texts.successInPasswordChange();
  };

  private renderConfirmDialog(): React.ReactNode {
    if (this.state.logOutDialogOpen) {
      return (
        <ConfirmationDialog
          title={translations.user.texts.logOut()}
          message={translations.user.texts.youAreAboutToLogOut()}
          onConfirm={(): Promise<void> => AuthWrapper.logOut()}
          onCancel={(): void => this.setState({ logOutDialogOpen: false })}
        />
      );
    }
  }

  private renderDialog(): React.ReactNode {
    const { userDetails, editOption, dialogOpen } = this.state;
    if (!userDetails) return;
    if (editOption === "Name") {
      return (
        <EditNameDialog
          firstName={userDetails.givenName}
          lastName={userDetails.familyName}
          dialogOpen={dialogOpen}
          onDialogClose={(saveSuccessful): Promise<void> => this.handleDialogClose(saveSuccessful)}
        />
      );
    } else if (editOption === "Language") {
      return (
        <EditLanguageDialog
          language={userDetails.language}
          dialogOpen={dialogOpen}
          onDialogClose={(saveSuccessful): Promise<void> => this.handleDialogClose(saveSuccessful)}
        />
      );
    } else if (editOption === "Mobile") {
      return (
        <EditMobileDialog
          mobile={userDetails.phoneNumber}
          dialogOpen={dialogOpen}
          onDialogClose={(saveSuccessFull): Promise<void> => this.handleDialogClose(saveSuccessFull)}
        />
      );
    } else if (editOption === "Email") {
      return (
        <EditEmailDialog
          email={userDetails.email}
          dialogOpen={dialogOpen}
          onDialogClose={(saveSuccessful): Promise<void> => this.handleDialogClose(saveSuccessful)}
        />
      );
    } else if (editOption === "Password") {
      return (
        <EditPasswordDialog
          dialogOpen={dialogOpen}
          onDialogClose={(saveSuccessful): Promise<void> => this.handleDialogClose(saveSuccessful)}
        />
      );
    } else {
      return null;
    }
  }

  private renderSnackbar(): React.ReactNode {
    return (
      <Fragment>
        <Snackbar open={this.state.savedState} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
          <Alert severity="success" onClose={this.handleSnackbarClose}>
            {this.generateSnackbarText()}
          </Alert>
        </Snackbar>
        <Snackbar open={!!this.state.errorMessage} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
          <Alert severity="error" onClose={this.handleSnackbarClose}>
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
      </Fragment>
    );
  }

  private renderUserFields(): React.ReactNode {
    const { userDetails } = this.state;
    return (
      <List className="profile-list">
        <ListItem className="profile-list-item" divider>
          <ProfileFieldItem
            label={translations.user.texts.name()}
            value={this.displayUserName()}
            onEdit={(): void => void this.handleFieldEdit("Name")}
          />
        </ListItem>
        <ListItem className="profile-list-item" divider>
          <ProfileFieldItem
            label={translations.user.inputs.email()}
            value={userDetails?.email ?? ""}
            onEdit={(): void => void this.handleFieldEdit("Email")}
          />
        </ListItem>
        <ListItem className="profile-list-item" divider>
          <ProfileFieldItem
            label={translations.logIn.inputs.password()}
            value="••••••••••••••"
            onEdit={(): void => void this.handleFieldEdit("Password")}
          />
        </ListItem>
        <ListItem className="profile-list-item" divider>
          <ProfileFieldItem
            label={translations.user.texts.language()}
            value={translations.user.texts.userLanguage()}
            onEdit={(): void => void this.handleFieldEdit("Language")}
            editDisabled
          />
        </ListItem>
      </List>
    );
  }

  private renderLoader(): React.ReactNode {
    return (
      <>
        <Loader />
        {this.renderSnackbar()}
      </>
    );
  }

  public render(): React.ReactNode {
    if (!this.state.userDetails) return this.renderLoader();
    return (
      <Grid container justifyContent="center">
        <Paper className="profile-paper" elevation={0}>
          {this.renderConfirmDialog()}
          <Grid container direction="column" spacing={2} justifyContent="center">
            <Grid item container direction="row" spacing={2} alignItems="center">
              <Grid item>
                <ProfileAvatar
                  firstname={this.state.userDetails.givenName}
                  lastname={this.state.userDetails.familyName}
                  sx={{ width: "64px", height: "64px", fontSize: "34px" }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5">{translations.user.texts.myProfile()}</Typography>
              </Grid>
            </Grid>
            <Grid item>{this.renderUserFields()}</Grid>
            <Grid item container justifyContent="center" style={{ display: "flex" }}>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  style={{ borderRadius: "8px", fontWeight: "bold" }}
                  onClick={(): void => {
                    this.setState({ logOutDialogOpen: true });
                  }}
                  startIcon={<Logout />}
                >
                  {translations.user.texts.logOut()}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {this.renderSnackbar()}
          <CopyrightAndTrademark />
        </Paper>
        {this.renderDialog()}
      </Grid>
    );
  }
}
