import React, { Component } from "react";
import HEADER_LOGO from "../../assets/CloudCONEKT-temporaryLogo.svg";
import { AuthenticatedUser } from "@sade/data-access";
import CompleteAccountCreationForm from "./components/complete-account-creation-form";
import ForgotPasswordUserForm from "./components/forgot-password-user-form";
import ForgotPasswordResetForm from "./components/forgot-password-reset-form";
import LoginForm from "./components/login-form";
import { translations } from "../../generated/translationHelper";
import { UserLicenseAgreementDialog } from "./components/user-license-agreement-dialog";
import { CopyrightAndTrademark } from "../ui/copyright-and-trademark";

interface Props {}

interface State {
  form: Forms;
  user?: string | AuthenticatedUser;
  UserLicenseAgreementDialogOpen: boolean;
}

export enum Forms {
  ForgotPasswordUser,
  ForgotPasswordReset,
  ConfirmSignUp,
  SignIn,
}

export default class LoginView extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      form: Forms.SignIn,
      UserLicenseAgreementDialogOpen: false,
    };
  }

  private renderInputFields(): JSX.Element {
    if (this.state.form === Forms.ForgotPasswordUser) {
      return (
        <ForgotPasswordUserForm
          onLoginRequest={(): void => this.setState({ form: Forms.SignIn })}
          onPasswordResetRequest={(user): void => this.setState({ form: Forms.ForgotPasswordReset, user })}
        />
      );
    } else if (this.state.form === Forms.ForgotPasswordReset && LoginView.isString(this.state.user)) {
      return (
        <ForgotPasswordResetForm
          onLoginRequest={(): void => this.setState({ form: Forms.SignIn })}
          username={this.state.user}
        />
      );
    } else if (this.state.form === Forms.ConfirmSignUp && this.state.user && !LoginView.isString(this.state.user)) {
      return (
        <CompleteAccountCreationForm
          user={this.state.user}
          openTermsOfUse={(): void => {
            this.setState({ UserLicenseAgreementDialogOpen: true });
          }}
        />
      );
    }
    return (
      <LoginForm
        onSignUpConfirmRequest={(user): void => this.setState({ form: Forms.ConfirmSignUp, user })}
        onPasswordResetRequest={(user): void => {
          if (user) {
            this.setState({ form: Forms.ForgotPasswordReset, user });
          } else {
            this.setState({ form: Forms.ForgotPasswordUser });
          }
        }}
      />
    );
  }

  private static isString(value: unknown): value is string {
    return typeof value === "string";
  }

  private renderUserLicenseAgreementDialog(): JSX.Element {
    if (this.state.UserLicenseAgreementDialogOpen) {
      return (
        <UserLicenseAgreementDialog
          close={(): void => {
            this.setState({ UserLicenseAgreementDialogOpen: false });
          }}
        ></UserLicenseAgreementDialog>
      );
    }
    return <></>;
  }

  public render(): JSX.Element {
    return (
      <>
        <section className="login-page-container">
          <section className="login-page-bg col-lg-6">
            <section className="login-container col-xsm-11 col-sm-9 col-md-6 col-lg-9 col-xlg-6">
              <header className="header-container login col-md-12">
                <div className="logo-container login col-xlg-10 col-lg-8 col-mg-8 col-sm-8 col-xsm-10">
                  <img className="header-logo" src={HEADER_LOGO} alt="Farpointe Data, Inc." />
                </div>
              </header>
              <div className="login-content">{this.renderInputFields()}</div>
              <div className="login-legal">
                <p className="login-legal-links">
                  <a href="mailto:support@farpointedata.com">{translations.logIn.texts.support()}</a>|
                  <a
                    href="#"
                    onClick={(): void => {
                      this.setState({ UserLicenseAgreementDialogOpen: true });
                    }}
                  >
                    {translations.logIn.texts.userLicenseAgreement()}
                  </a>
                </p>
                <CopyrightAndTrademark sx={{ paddingY: 0 }} />
                <p className="login-version-number">{process.env.REACT_APP_WEBUI_VERSION}</p>
              </div>
            </section>
          </section>
        </section>
        {this.renderUserLicenseAgreementDialog()}
      </>
    );
  }
}
