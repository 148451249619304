/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
const WILDCARD = "*";
function featureEndsInWildCard(feature) {
    return feature.endsWith(WILDCARD);
}
function removeWildcard(feature) {
    return featureEndsInWildCard(feature) ? feature.substring(0, feature.length - 1) : feature;
}
/**
 * Checks if first feature is wide enough to cover another.
 * This is true, if the feature ends with * AND another starts with feature[0, length-1].
 *
 * Note: this method is not case-sensitive, but features should be:
 * provide the strings in lower-case.
 *
 * @param feature
 *    potential prefix
 * @param another
 *    feature to check
 */
function featureCoversAnother(feature, another) {
    if (!featureEndsInWildCard(feature)) {
        return false;
    }
    const prefix = feature.substring(0, feature.length - 1);
    return another.startsWith(prefix);
}
/**
 * Checks if given feature is already part of the feature list (including wildcards).
 * Case insensitive.
 *
 * @param featureList
 * @param feature
 */
export function featureListCoversFeature(featureList, feature) {
    const featureLowerCase = feature.toLowerCase();
    const coveringFeature = featureList.find((listFeature) => {
        const listFeatureLowerCase = listFeature.toLowerCase();
        return listFeatureLowerCase === featureLowerCase || featureCoversAnother(listFeatureLowerCase, featureLowerCase);
    });
    return Boolean(coveringFeature);
}
/**
 * Removes features covered by other features in the set.
 * Immutable operation.
 * Case-insensitive.
 *
 * @param set
 */
export function minimizeSetOfFeatures(set) {
    if (set.size === 0)
        return new Set();
    const orderedFeatures = [...set].sort((a, b) => removeWildcard(a).length - removeWildcard(b).length);
    const [start, ...rest] = orderedFeatures;
    const finalFeatures = [start];
    for (const feature of rest) {
        if (!featureListCoversFeature(finalFeatures, feature)) {
            finalFeatures.push(feature);
        }
    }
    return new Set(finalFeatures);
}
