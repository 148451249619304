import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { Component } from "react";
import { Maybe, Organization, OrganizationObserver, Role, User } from "@sade/data-access";
import ConfirmationDialog from "../../../ui/confirmation-dialog";
import ErrorDialog from "../../../ui/error-dialog";
import Loader from "../../../ui/loader";
import NewUserForm from "./new-user-form";
import UserListItem from "./user-list-item";
import { UserOrganizationAction } from "./user-organization-action";
import { translations } from "../../../../generated/translationHelper";

// current user's permissions within the context of the current organization
interface UserPermissions {
  deleteUser: boolean;
  removeUser: boolean;
  assignRoles: boolean;
}

const NO_PERMISSIONS: UserPermissions = {
  removeUser: false,
  deleteUser: false,
  assignRoles: false,
};

interface Props {
  organization: Organization;
  currentUser: User;
  allRoles: Role[];
}

interface State {
  loading: boolean;
  users: User[];
  action?: { user: User; action: UserOrganizationAction };
  userPermissions?: UserPermissions;
  availableRoles?: Role[];
  errorMsg?: string;
}

export default class UserList extends Component<Props, State> implements OrganizationObserver {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      users: [],
    };
  }

  public componentDidMount(): void {
    this.props.organization.addObserver(this);
    this.fetchRoles().then();
    this.performUpdates();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.organization.getId() !== this.props.organization.getId()) {
      prevProps.organization.removeObserver(this);
      this.props.organization.addObserver(this);
      this.performUpdates();
    }
  }

  public componentWillUnmount(): void {
    this.props.organization.removeObserver(this);
  }

  public onUsersChange(usersList: User[]): void {
    this.setState({
      users: usersList,
      loading: false,
    });
  }

  private performUpdates(): void {
    this.setState({
      loading: true,
      userPermissions: undefined,
      availableRoles: undefined,
      users: [],
    });
    Promise.allSettled([this.updateUserPermissions(), this.updateUsers(), this.fetchRoles()]).finally(() => {
      this.setState({ loading: false });
    });
  }

  private async fetchRoles(): Promise<void> {
    const { currentUser, organization } = this.props;
    try {
      const availableRoles = await currentUser.getRolesUserCanAssign(organization.getId());
      console.log("fetchRoles", organization.getId(), availableRoles);
      this.setState({ availableRoles });
    } catch (err) {
      console.error("Failed to fetch roles", err);
    }
  }

  private async updateUserPermissions(): Promise<void> {
    try {
      const organizationId = this.props.organization.getId();
      const allowedActions = await Promise.all([
        this.props.currentUser.hasFeatures(organizationId, "usersWrite"),
        this.props.currentUser.hasFeatures(organizationId, "organizationsUpdate"),
      ]);

      this.setState({
        userPermissions: {
          deleteUser: allowedActions[0],
          removeUser: allowedActions[1],
          assignRoles: allowedActions[1],
        },
      });
    } catch (err) {
      console.error("updateUserPermissions", err);
      this.setState({ userPermissions: NO_PERMISSIONS });
    }
  }

  private async updateUsers(): Promise<void> {
    try {
      const users = await this.props.organization.getUsers();
      this.setState({
        users,
      });
    } catch (err) {
      console.error("updateUsers", err);
      this.setState({
        users: [],
      });
    }
  }

  private handleUserAction = (action: UserOrganizationAction, user: User): void => {
    this.setState({
      action: {
        user,
        action,
      },
    });
  };

  private handleSubmitAction = async (): Promise<void> => {
    const action = this.state.action;

    if (!action) {
      return;
    }

    this.setState({ loading: true, action: undefined });

    try {
      if (action.action === UserOrganizationAction.RemoveFromOrganization) {
        await this.props.organization.removeUser(action.user);
      } else if (action.action === UserOrganizationAction.DeleteUser) {
        await action.user.delete();
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({
        loading: false,
        errorMsg: translations.admin.texts.failedToDeleteUser(),
      });
    }
  };

  private handleCancelAction = (): void => {
    this.setState({ action: undefined });
  };

  private handleCloseErrorNote = (): void => {
    this.setState({ errorMsg: undefined });
  };

  private renderUserTableItems(): JSX.Element | JSX.Element[] {
    const users = this.state.users;
    const permissions = this.state.userPermissions;
    const availableRoles = this.state.availableRoles;

    const getUserAction = (user: User): UserOrganizationAction => {
      if (user.getId() === this.props.currentUser.getId()) {
        return UserOrganizationAction.Nothing;
      } else if (user.getHomeOrganizationId() === this.props.organization.getId()) {
        return permissions?.deleteUser ? UserOrganizationAction.DeleteUser : UserOrganizationAction.Nothing;
      } else {
        return permissions?.removeUser ? UserOrganizationAction.RemoveFromOrganization : UserOrganizationAction.Nothing;
      }
    };

    if (users) {
      return users.map((user: User, index: number) => {
        return (
          <UserListItem
            key={user.getId()}
            user={user}
            allRoles={this.props.allRoles}
            userAction={getUserAction(user)}
            organizationId={this.props.organization.getId()}
            availableRoles={availableRoles ?? []}
            actionCallback={this.handleUserAction}
            enableRoleSelection={user.getId() !== this.props.currentUser.getId() && (permissions?.assignRoles ?? false)}
            data-testid={`user-${index}`}
          />
        );
      });
    } else {
      return (
        <tr>
          <td className="users-list-empty-text">{translations.admin.texts.noUsers()}</td>
        </tr>
      );
    }
  }

  private renderUsersList = (): JSX.Element | JSX.Element[] => {
    if (this.state.loading) {
      return <Loader />;
    } else if (this.state.errorMsg) {
      return <ErrorDialog errorMsg={this.state.errorMsg} onClose={this.handleCloseErrorNote} />;
    } else {
      return (
        <Table className="org-users-table" data-testid="users-table">
          <TableHead>
            <TableRow>
              <TableCell>{translations.common.texts.username()}</TableCell>
              <TableCell align="center">{translations.admin.texts.role()}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{this.renderUserTableItems()}</TableBody>
        </Table>
      );
    }
  };

  private renderActionPopup(): Maybe<JSX.Element> {
    if (!this.state.action) {
      return;
    }
    const title =
      this.state.action.action === UserOrganizationAction.DeleteUser
        ? translations.admin.texts.deleteUser()
        : this.state.action.action === UserOrganizationAction.RemoveFromOrganization
        ? translations.admin.texts.removeUser()
        : undefined;

    if (!title) {
      throw new Error("Unknown action selected!");
    }

    if (this.state.action) {
      return (
        <ConfirmationDialog
          title={title}
          message={this.state.action.user.getEmail()}
          onConfirm={this.handleSubmitAction}
          onCancel={this.handleCancelAction}
        />
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Grid container direction="column" rowGap={3} sx={{ padding: "0 24px" }}>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginTop: "24px" }}
        >
          <Typography variant="h6">{translations.admin.texts.users()}</Typography>
        </Grid>
        <Grid item>
          {this.state.availableRoles && (
            <NewUserForm
              showAccessError={false}
              showLoader={false}
              contextOrganization={this.props.organization}
              organization={this.props.organization}
              availableRoles={this.state.availableRoles}
            />
          )}
          {this.renderUsersList()}
          {this.renderActionPopup()}
        </Grid>
      </Grid>
    );
  }
}
