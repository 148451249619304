import { AWSUser } from "./AWSUser";
import { Organization, } from "./Organization";
import { User } from "./User";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { OrganizationsDeleteDocument, OrganizationsOrganizationsListDocument, OrganizationsUpdateDocument, OrganizationsUsersListDocument, OrganizationsUsersRemoveDocument, ResultType, } from "../../generated/gqlUsers";
import { verifyUserType } from "./AWSTypeUtils";
import { throwGQLError } from "../private-utils/throwGQLError";
import { PromiseSemaphore } from "../private-utils/PromiseSemaphore";
export class AWSOrganization extends Organization {
    constructor(backend, parameters) {
        super(parameters);
        this.backend = backend;
        this.entityType = AWSOrganization;
        this.usersSemaphore = new PromiseSemaphore(() => this.backendFetchUsers());
        this.childrenSemaphore = new PromiseSemaphore(() => this.backendFetchChildOrganizations());
    }
    static instanceOf(value) {
        return value instanceof AWSOrganization;
    }
    async changeProps(name, maxSecureCode) {
        await this.backendChangeProps(name, maxSecureCode);
        this.name = name;
        this.maxSecureCode = maxSecureCode;
        this.notifyAction((observer) => { var _a; return (_a = observer.onPropertiesChange) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
    }
    async getUsers() {
        await this.usersSemaphore.guard();
        const result = this.backend.entityRelationCache.listFor(this, AWSUser);
        result.sort(User.alphabeticUserOrdering);
        return result;
    }
    async createOrganization(parameters) {
        const newOrganization = await this.backend.createOrganization(this, parameters);
        await this.getDirectChildOrganizations();
        this.backend.entityRelationCache.link(this, newOrganization);
        return newOrganization;
    }
    async getDirectChildOrganizations() {
        await this.childrenSemaphore.guard();
        return (this.backend.entityRelationCache
            .listFor(this, AWSOrganization)
            // the relationship set will include parent node, so that needs to be removed
            .filter((potentialChild) => potentialChild.id.startsWith(this.id)));
    }
    async getAllChildOrganizationsRecursively() {
        const results = [];
        const directChildren = await this.getDirectChildOrganizations();
        const grandChildren = [];
        const parallelism = 50;
        for (let i = 0; i < directChildren.length; i += parallelism) {
            const start = i * parallelism;
            const end = start + parallelism;
            const batchOfChildren = directChildren.slice(start, end);
            const promises = [];
            for (const kid of batchOfChildren) {
                promises.push(kid.getAllChildOrganizationsRecursively());
            }
            for (const orgs of await Promise.all(promises)) {
                grandChildren.push(...orgs);
            }
        }
        // the result should be ordered so that an organization is immediately followed by its children (each which are followed by their own children)
        // and sibling organizations are sorted alphabetically by their name
        directChildren.sort((a, b) => a.getName().localeCompare(b.getName()));
        for (const kid of directChildren) {
            results.push(kid);
            results.push(...grandChildren.filter((o) => Organization.isParentOrEqualOrganizationId(o.getId(), kid.getId())));
        }
        return results;
    }
    async getParentOrganization() {
        if (this.parentId) {
            return this.backend.getOrganization(this.parentId);
        }
    }
    async createUser(parameters) {
        try {
            const newUser = await this.backend.createUser(this, parameters);
            this.backend.entityRelationCache.link(this, newUser);
            return newUser;
        }
        catch (error) {
            console.error("Failed to create new user: " + error);
            throw error;
        }
    }
    async addUser(user, roles) {
        verifyUserType(user);
        try {
            await this.backend.addUserToOrganization(this, user, roles);
        }
        catch (error) {
            console.error("Failed to add user to organization: " + error);
            throw error;
        }
    }
    async removeUser(user) {
        verifyUserType(user);
        const removed = await this.backendRemoveUser(user.getId());
        if (removed) {
            this.backend.entityRelationCache.unlink(this, user);
        }
    }
    async delete() {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsDeleteDocument, {
            organizationId: this.id,
        });
        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsDelete) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throw new Error((_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.organizationsDelete) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to delete organization");
        }
        await this.backend.cleanEntityFromCaches(this.id);
        this.notifyAction((observer) => { var _a; return (_a = observer.onDeleted) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
        this.clearObservers();
    }
    async onRelationChange(change) {
        // using semaphore.invoked() to check if some external entity is interested in the particular updates
        // otherwise we'd end up greedily loading resources (such as the whole organization tree)
        if (change.ofType(AWSOrganization) && this.childrenSemaphore.invoked()) {
            const children = await this.getDirectChildOrganizations();
            this.notifyAction((observer) => { var _a; return (_a = observer.onChildrenChange) === null || _a === void 0 ? void 0 : _a.call(observer, children, this); });
        }
        else if (change.ofType(AWSUser) && this.usersSemaphore.invoked()) {
            const users = await this.getUsers();
            this.notifyAction((observer) => { var _a; return (_a = observer.onUsersChange) === null || _a === void 0 ? void 0 : _a.call(observer, users, this); });
        }
    }
    async backendChangeProps(name, maxSecureCode) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        await client.mutate(OrganizationsUpdateDocument, {
            payload: {
                id: this.id,
                name,
                maxSecureCode,
            },
        });
    }
    async backendFetchUsers() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.query(OrganizationsUsersListDocument, {
            organizationId: this.id,
            // TODO: token
        }, {
            fetchPolicy: "network-only",
        });
        if (!response.data.organizationsUsersList) {
            throwGQLError(response, "Failed to get organization's users");
        }
        // TODO:  in order to improve performance here, we need to decide what data should be duplicated
        //        from user to the organization-user link row, and use that to construct a simplified view to the user
        //        ... or we can just resolve the users on the service side, but that is as expensive as OrganizationUtils.
        //            maybe a little faster
        //        ... or we could add some smart indexes here and there for specific use-cases, such as this (although,
        //            custom indexing org-users relationship is pretty hard)
        //
        //        Currently, the backend does a lot of caching, so that helps with repeated requests
        const maybeUsers = await Promise.all(response.data.organizationsUsersList.users.map((id) => this.backend.getUser(id).catch((err) => {
            console.warn(`Failed to get user ${id} for organization ${this.id}: ${err}`);
            return undefined;
        })));
        const users = maybeUsers.filter(AWSUser.instanceOf);
        this.backend.entityRelationCache.replaceTypedLinks(this, AWSUser, users);
    }
    async backendFetchChildOrganizations() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.query(OrganizationsOrganizationsListDocument, {
            organizationId: this.id,
            // TODO: next token
        }, {
            fetchPolicy: "network-only",
        });
        if (!response.data.organizationsOrganizationsList) {
            throwGQLError(response, "Failed to get organization's child organizations");
        }
        // TODO: this is also very slow, much like backendGetUsers
        const organizations = await Promise.all(response.data.organizationsOrganizationsList.organizations.map((id) => this.backend.getOrganization(id).catch((err) => {
            console.warn(`Failed to get organization ${id} for organization ${this.id}: ${err}`);
            return undefined;
        })));
        const children = organizations.filter(AWSOrganization.instanceOf);
        // keeps the potential parent in the cache, otherwise we are in trouble
        this.backend.entityRelationCache.replaceTypedLinks(this, AWSOrganization, children, (record) => record.entity.id === this.parentId);
    }
    async backendRemoveUser(id) {
        var _a, _b;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsUsersRemoveDocument, {
            userId: id,
            organizationId: this.id,
        });
        return ((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsUsersRemove) === null || _b === void 0 ? void 0 : _b.result) === ResultType.Ok;
    }
}
