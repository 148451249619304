import { colors } from "@mui/material";
import { PaletteOptions } from "@mui/material/styles/createPalette";

const white = "#ffffff";

declare module "@mui/material/styles" {
  interface Palette {
    black: Palette["primary"];
  }

  interface PaletteOptions {
    black?: PaletteOptions["primary"];
  }
}

const lightPalette: PaletteOptions = {
  background: {
    default: white,
    paper: white,
  },
  primary: {
    main: "#EE2737",
    light: "#F1525F",
    dark: "#A61B26",
  },
  secondary: {
    main: "#EE2737",
    light: "#F1525F",
    dark: "#A61B26",
  },
  error: colors.purple,
  warning: colors.deepOrange,
  info: {
    main: colors.lightBlue[700],
  },
  success: {
    main: colors.green[800],
  },
  text: {
    disabled: "rgba(0, 0, 0, 0.38)",
    primary: colors.grey[900],
    secondary: "rgba(0, 0, 0, 0.6)",
  },
  tonalOffset: 0.1,
  mode: "light",
  black: {
    main: "#000",
    light: "#000",
    dark: "#000",
    contrastText: "#000",
  },
};

// TODO: this does not work at all since the theming has so much custom coloring going on
const darkPalette: PaletteOptions = {
  background: {
    default: colors.grey[900],
    paper: colors.grey[800],
  },
  primary: colors.blue,
  secondary: colors.lightBlue,
  error: colors.red,
  warning: colors.deepOrange,
  info: colors.cyan,
  success: colors.green,
  text: {
    disabled: colors.grey[700],
    primary: colors.grey[50],
    secondary: colors.grey[200],
  },
  tonalOffset: 0.1,
  mode: "dark",
  black: {
    main: "#000",
    light: "#000",
    dark: "#000",
    contrastText: "#000",
  },
};

const AppPalette = {
  lightPalette,
  darkPalette,
};

export default AppPalette;
