import React, { Component, ReactNode } from "react";
import Loader from "../ui/loader";
import { Typography } from "@mui/material";
import { BackendFactory, Organization, Feature, User } from "@sade/data-access";

interface State {
  hasAccess?: boolean;
}

interface Props<TWrappedProps> {
  /**
   * Organization(Id) for the organization within which to perform permission checks
   * if no context organization is provided, uses user's home organization
   */
  contextOrganization?: string | Organization;
  /**
   * Whether to show error message if the user does not have access.
   * true by default
   */
  showAccessError?: true | boolean;
  /**
   * Whether to show {@code <Loader>} component while resolving access status
   * {@code true} by default
   */
  showLoader?: true | boolean;
  /**
   * Alternative for the <Loader> component. Only used if showLoader = true
   */
  loaderComponent?: React.JSX.Element;
  /**
   * Set of props to pass to the wrapped component, when the user does not have access to the component
   * (access is being checked or access has been denied).
   * setting this implies showAccessError = false && showLoader = false
   * Example value: { disabled: true }, given that wrapped component has prop "disabled"
   */
  accessDeniedProps?: Partial<TWrappedProps>;
  /**
   * Triggered upon gaining access
   */
  onAccessGranted?: () => void;
}

// list of permissions the user requires in order to access the component
type FeatureList = Feature[];
// async callback for checking if the user has access to the component
type AuthorizationCallback = (user: User) => Promise<boolean>;

/**
 * HOC (https://reactjs.org/docs/higher-order-components.html) for adding access control for a component
 * @param WrappedComponent
 *    component which required access control, such as admin panel components
 * @param permissionsOrCallback
 *    either a list of required permissions or a callback method for performing authorization
 */
export default function accessControlled<TProps>(
  WrappedComponent: React.ComponentType<TProps>,
  featuresOrCallback: FeatureList | AuthorizationCallback
): React.ComponentClass<Props<TProps> & TProps> {
  if (Array.isArray(featuresOrCallback) && featuresOrCallback.length === 0) {
    throw new Error("featuresOrCallback cannot be an empty array");
  }

  return class AccessControlledContent extends Component<Props<TProps> & TProps, State> {
    public constructor(props: Props<TProps> & TProps) {
      super(props);
      this.state = {};
    }

    public async componentDidMount(): Promise<void> {
      const user = await BackendFactory.getOrganizationBackend().getCurrentUser();

      if (!user) {
        console.warn("Failed to properly mount access controlled component: no authenticated user");
        return;
      }

      let hasPermissions = false;

      if (Array.isArray(featuresOrCallback)) {
        const organization =
          typeof this.props.contextOrganization === "string"
            ? this.props.contextOrganization
            : this.props.contextOrganization?.getId();

        hasPermissions = await user.hasFeatures(organization ?? user.getHomeOrganizationId(), ...featuresOrCallback);

        this.setState({
          hasAccess: hasPermissions,
        });
      } else {
        hasPermissions = await featuresOrCallback(user);

        this.setState({
          hasAccess: hasPermissions,
        });
      }

      if (hasPermissions && this.props.onAccessGranted) {
        this.props.onAccessGranted();
      }
    }

    public render(): ReactNode {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        contextOrganization,
        showAccessError,
        showLoader,
        loaderComponent,
        accessDeniedProps,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onAccessGranted,
        ...rest
      } = this.props;

      const showComponent = this.state.hasAccess === true || accessDeniedProps;

      if (showComponent) {
        const wrappedProps = this.state.hasAccess ? rest : { ...rest, ...accessDeniedProps };
        return <WrappedComponent {...(wrappedProps as TProps & React.JSX.IntrinsicAttributes)} />;
      } else if (this.state.hasAccess == null) {
        if (showLoader ?? true) {
          return loaderComponent ?? <Loader />;
        }
      } else if (!this.state.hasAccess) {
        if (showAccessError ?? true) {
          return (
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <Typography variant="h3">Invalid access rights, nothing to see here ;)</Typography>
            </div>
          );
        }
      }
      return null;
    }
  };
}
