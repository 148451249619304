import { createTheme, Theme } from "@mui/material";
import AppPalette from "./app-palette";
import { GridClassKey } from "@mui/x-data-grid";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const theme = createTheme({
  palette: AppPalette.lightPalette,
  typography: {
    fontFamily: "Roboto",
    h5: {
      fontSize: 24,
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          fontWeight: 500,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontWeight: "bold",
          textTransform: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: 8,
        },
        input: {
          fontSize: 16,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: "primary",
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: "uppercase",
          fontSize: 12,
          lineHeight: "normal",
          marginBottom: "4px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: "uppercase",
          fontSize: 12,
          color: "text.secondary",
          lineHeight: "normal",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
          margin: 1,
          maxHeight: "calc(100% - 16px)",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 16,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 24,
          paddingBottom: 24,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: 24,
          boxShadow: "none",
          border: "1px solid #0000001F",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingBottom: 16,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "normal",
          "&:last-child": {
            padding: 0,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px", // 171.429%. Unlikely to ever matter but it is in figma.
          letterSpacing: "0.4px",
          height: "64px",
          color: "#00000099",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            fontWeight: "bold",
          },
        },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          minHeight: "calc(100vh - 238px)",
          "& .MuiDataGrid-cell": { paddingLeft: 16, paddingRight: 16 },
          "& .MuiDataGrid-columnHeaderTitleContainer": { paddingLeft: 0, paddingRight: 0 },
          "& .MuiDataGrid-columnHeader": { paddingLeft: 16, paddingRight: 16 },
          "& .MuiDataGrid-virtualScroller": { overflow: "initial" },
        },
      },
    },
  },
});

// https://mui.com/x/react-data-grid/getting-started/#typescript
// import type {} from "@mui/x-data-grid/themeAugmentation"
// the above import should do it, but for some reason it does not, so here we implement theme augmentation ourselves
declare module "@mui/material/styles/components" {
  interface Components {
    MuiDataGrid?: {
      styleOverrides?: Partial<OverridesStyleRules<GridClassKey, "MuiDataGrid", Theme>>;
    };
  }
}
